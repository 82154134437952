import React, { useEffect } from 'react'
import { API } from '@aws-amplify/api'
import { Storage } from '@aws-amplify/storage'

import { useLoading } from '_core/context/LoadingContext'
import { useMessage } from '_core/context/MessageContext'
import { useAuthenticatedLocal } from 'account/context/AuthenticatedLocalContext'

import { accountReducer, initialState, State, Dispatch } from './accountReducer'

import AWSConfig from 'account/constants/aws-config'
import accountQueries, {
  queryGet,
  queryUpdate,
} from 'account/constants/account-queries'

API.configure(AWSConfig)
Storage.configure(AWSConfig)

const AccountContext = React.createContext<
  | {
      state: State
      dispatch: Dispatch
      fetch: Function
      update: Function
      getRelevantMandateIds: Function
      getPicture: Function
      upload: Function
      hasInterests: Function
    }
  | undefined
>(undefined)

type uploadProps = {
  file: File
  filename: string
  folder: 'documents' | 'images'
}

type updateProps = {
  type: keyof queryUpdate
  variables: Object
}

export type awsStorageResult = {
  key: string
}

type AccountProviderProps = { children: React.ReactNode }

const AccountProvider = ({ children }: AccountProviderProps) => {
  // console.log('AccountProvider()')
  const { dispatch: loadingDispatch } = useLoading()
  const { dispatch: messageDispatch } = useMessage()
  const { isAuthenticatedLocal } = useAuthenticatedLocal()
  const [state, dispatch] = React.useReducer(accountReducer, {
    ...initialState,
  })

  // const isAwsAuthenticated = async () => {
  //   try {
  //     await Auth.currentAuthenticatedUser().then(() => {
  //       dispatch({ type: 'SET_AWSAUTHENTICATED' })
  //     })
  //   } catch (err) {
  //     dispatch({ type: 'UNSET_AWSAUTHENTICATED' })
  //   }
  // }

  const fetch = async (type: keyof queryGet) => {
    // console.log('AccountProvider() -> fetch()')
    try {
      loadingDispatch({ type: 'START_LOADING' })
      const result = await API.post('app', '/app', {
        body: {
          operationName: null,
          query: accountQueries.get[type],
        },
      })

      // console.log('********************************************************')
      // console.log('🚀 ~ file: AccountContext.tsx:84 ~ fetch ~ result:', result)
      const selector = accountQueries.dataSelectors.get[type]

      dispatch({
        type: 'SET_STATE',
        payload: {
          type: type,
          data: result.data[selector],
        },
      })
      loadingDispatch({ type: 'STOP_LOADING' })
    } catch (err) {
      console.log('🚀 ~ file: AccountContext.tsx:105 ~ fetch ~ err:', err)
      loadingDispatch({ type: 'STOP_LOADING' })
    }
  }

  const fetchAll = () => {
    // console.log('AccountProvider() -> fetchAll()')
    if (!state.account) {
      fetch('account')
    }
    if (!state.interests) {
      fetch('interests')
    }
    if (!state.favoriteMandate) {
      fetch('favoriteMandate')
    }
    if (!state.applications) {
      fetch('applications')
    }
    if (!state.votes) {
      fetch('votes')
    }
    if (!state.profile) {
      fetch('profile')
    }
  }

  const update = async ({ type, variables }: updateProps) => {
    let stateType: keyof queryGet
    switch (type) {
      case 'unfavoriteMandate': {
        stateType = 'favoriteMandate'
        break
      }
      case 'upVotePost':
      case 'downVotePost': {
        stateType = 'votes'
        break
      }
      default:
        stateType = type
        break
    }

    try {
      loadingDispatch({ type: 'START_LOADING' })
      const result = await API.post('app', '/app', {
        body: {
          operationName: null,
          variables: variables,
          query: accountQueries.update[type],
        },
      })
      const selector = accountQueries.dataSelectors.update[type]
      let data = result.data[selector]

      if (stateType === 'votes') {
        fetch('votes')
      } else {
        dispatch({
          type: 'SET_STATE',
          payload: {
            type: stateType,
            data: data,
          },
        })
      }
      loadingDispatch({ type: 'STOP_LOADING' })
      messageDispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: accountQueries.statusMessages[type].update.success,
          status: 'success',
        },
      })
    } catch (err) {
      loadingDispatch({ type: 'STOP_LOADING' })
      messageDispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: accountQueries.statusMessages[type].update.error,
          status: 'error',
        },
      })
    }
  }

  const getRelevantMandateIds = async () => {
    try {
      loadingDispatch({ type: 'START_LOADING' })
      const result = await API.post('app', '/app', {
        body: {
          operationName: null,
          query: `{
                  relevantMandates {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }`,
        },
      })
      loadingDispatch({ type: 'STOP_LOADING' })
      return result.data.relevantMandates
    } catch (err) {
      console.log('err: ', err)
      loadingDispatch({ type: 'STOP_LOADING' })
    }
  }

  const upload = async ({ file, filename, folder }: uploadProps) => {
    const path = `${folder}/${filename}`
    try {
      loadingDispatch({ type: 'START_UPLOADING' })
      const d = new Date()
      const result = await Storage.put(path, file, {
        level: 'private',
        contentType: file.type,
        expires: new Date(d.setSeconds(d.getSeconds() + 60)),
      })
      loadingDispatch({ type: 'STOP_UPLOADING' })
      return result
    } catch (err) {
      console.log('error uploading... ', err)
      loadingDispatch({ type: 'STOP_UPLOADING' })
    }
  }

  const getPicture = async () => {
    if (state.account && state.account.picture) {
      try {
        const result = await Storage.vault.get(state.account.picture)
        return result
      } catch (err) {
        console.log(err)
      }
    }
  }

  const hasInterests = () => {
    let hasInterests = false
    if (state.interests && Object.keys(state.interests).length > 0) {
      Object.keys(state.interests).map((key) => {
        if (
          state.interests[key as keyof typeof state.interests] &&
          state.interests[key as keyof typeof state.interests].length > 0
        ) {
          hasInterests = true
        }
      })
    }
    return hasInterests
  }

  useEffect(() => {
    if (isAuthenticatedLocal()) {
      fetchAll()
    }
  }, [state.awsAuthenticated])

  const value = {
    state,
    fetch,
    dispatch,
    update,
    getRelevantMandateIds,
    upload,
    getPicture,
    hasInterests,
  }

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  )
}

const useAccount = () => {
  const context = React.useContext(AccountContext)
  if (context === undefined) {
    throw new Error('useAccount must be used within a AccountProvider')
  }
  return context
}

export { AccountProvider, useAccount }
