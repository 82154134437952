const config = {
  apiGateway: {
    REGION: 'eu-west-2',
    URL: process.env.GATSBY_API_ENDPOINT,
  },
  cognito: {
    REGION: 'eu-west-2',
    USER_POOL_ID: 'eu-west-2_LZuJiVH2q',
    APP_CLIENT_ID: '5odbjibjg8lk0cdv2tpots9sb7',
    IDENTITY_POOL_ID: 'eu-west-2:ed155966-ad6e-4585-8668-c3d7568ef16f',
  },
}

const awsConfig = {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'app',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: 'deltaexec',
      region: 'eu-west-2',
    },
  },
}

export default awsConfig
