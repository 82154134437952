type queryCore = {
  account: string
  interests: string
  profile: string
  favoriteMandate: string
  applications: string
}
export type queryGet = queryCore & {
  votes: string
}
export type queryUpdate = queryCore & {
  unfavoriteMandate: string
  upVotePost: string
  downVotePost: string
}

type messages = {
  update: {
    success: string
    error: string
  }
}

export type accountQueriesType = {
  get: queryGet
  update: queryUpdate
  fields: {
    account: string
    favoriteMandate: string
    applications: string
    votes: string
    interests: string
    profile: string
  }
  dataSelectors: {
    get: queryGet
    update: queryUpdate
  }
  statusMessages: {
    interests: messages
    profile: messages
    account: messages
    favoriteMandate: messages
    unfavoriteMandate: messages
    upVotePost: messages
    downVotePost: messages
    applications: messages
  }
}

let queries: accountQueriesType = {
  get: {
    account: '',
    interests: '',
    profile: '',
    favoriteMandate: '',
    applications: '',
    votes: '',
  },
  update: {
    account: '',
    interests: '',
    profile: '',
    favoriteMandate: '',
    unfavoriteMandate: '',
    applications: '',
    upVotePost: '',
    downVotePost: '',
  },
  dataSelectors: {
    get: {
      interests: 'interests',
      profile: 'profile',
      account: 'account',
      favoriteMandate: 'favorites',
      applications: 'applications',
      votes: 'votes',
    },
    update: {
      interests: 'updateMyInterests',
      profile: 'updateMyProfile',
      account: 'updateMyAccount',
      favoriteMandate: 'favoriteMandate',
      applications: 'applyForMandate',
      unfavoriteMandate: 'unfavoriteMandate',
      upVotePost: 'upvotePost',
      downVotePost: 'downvotePost',
    },
  },
  statusMessages: {
    interests: {
      update: {
        success: 'Your Interests have been successfully updated.',
        error:
          'An error occurred when trying to save your Interests. Please try again.',
      },
    },
    profile: {
      update: {
        success: 'Your Employment Profile has been successfully updated.',
        error:
          'An error occurred when trying to save your Employment Profile. Please try again.',
      },
    },
    account: {
      update: {
        success: 'Your Account Settings have been successfully updated.',
        error:
          'An error occurred when trying to save your Account Settings. Please try again.',
      },
    },
    favoriteMandate: {
      update: {
        success: 'Mandate has been added to your favourites.',
        error:
          'An error occurred when trying to add a favourite mandate. Please try again.',
      },
    },
    unfavoriteMandate: {
      update: {
        success: 'Mandate has been removed from your favourites.',
        error:
          'An error occurred when trying to remove a favourite mandate. Please try again.',
      },
    },
    upVotePost: {
      update: {
        success: 'Like Successfull',
        error:
          'An error occurred when trying to like this post. Please try again.',
      },
    },
    downVotePost: {
      update: {
        success: 'Dislike Successfull',
        error:
          'An error occurred when trying to dislike this post. Please try again.',
      },
    },
    applications: {
      update: {
        success: 'Mandate application is successful.',
        error:
          'An error occurred when trying to apply for a mandate. Please try again.',
      },
    },
  },
  fields: {
    account: `
          email
          firstName
          lastName
          picture
          title
          notifications
          mailingList
    `,
    favoriteMandate: `
          id
    `,
    applications: `
          id
          modifiedAt
    `,
    votes: `
          id
          modifiedAt
          vote
    `,
    interests: `
            departments {
              slug
              name
            }
            regions {
              slug
              name
            }
            levels {
              slug
              name
            }
            locations {
              slug
              name
            }
        `,
    profile: `
          yearsOfExperience
          currentEmployer {
            employer
            jobTitle
          }
          departments {
            slug
            name
          }
          regions {
            slug
            name
          }
          levels {
            slug
            name
          }
          locations {
            slug
            name
          }
          products {
            slug
            name
          }
          sectors {
            slug
            name
          }
          cv
          linkedIn
          contactInformation {
            mobileNumber
          }
          languages{
            language
            proficiency {
              name
              slug
            }
          }
        `,
  },
}

queries.get.account = `{
          account {
            ${queries.fields.account}
          }
        }`
queries.get.profile = `{
          profile {
            ${queries.fields.profile}
          }
        }`
queries.get.interests = `{
          interests {
            ${queries.fields.interests}
          }
        }`
queries.get.favoriteMandate = `{
          favorites {
            ${queries.fields.favoriteMandate}
          }
        }`
queries.get.applications = `{
          applications {
            ${queries.fields.applications}
          }
        }`
queries.get.votes = `{
          votes {
            ${queries.fields.votes}
          }
        }`

queries.update.interests = `
          mutation update($interests: InterestsInput!) {
            updateMyInterests(interests: $interests) {
              ${queries.fields.interests}
            }
          }
        `

queries.update.applications = `
          mutation update($mandate: MandateReferenceInput!) {
            applyForMandate(mandate: $mandate) {
              id
            }
          }
        `

queries.update.favoriteMandate = `
          mutation updateFavoriteMandate($mandate: MandateReferenceInput!) {
            favoriteMandate(mandate: $mandate) {
              id
            }
          }
        `
queries.update.unfavoriteMandate = `
          mutation updateUnFavoriteMandates($mandate: MandateReferenceInput!) {
            unfavoriteMandate(mandate: $mandate) {
              id
            }
          }
        `

queries.update.account = `
          mutation updateAccount($account: AccountInput!) {
            updateMyAccount(account: $account) {
              ${queries.fields.account}
            }
          }
        `

queries.update.upVotePost = `
            mutation upvotePost($post: VoteReferenceInput!) {
              upvotePost(post: $post) {
                ${queries.fields.votes}
              }
            }
          `
queries.update.downVotePost = `
            mutation downvotePost($post: VoteReferenceInput!) {
              downvotePost(post: $post) {
                ${queries.fields.votes}
              }
            }
          `
queries.update.profile = `
            mutation updateProfile($profile: ProfileInput!) {
              updateMyProfile(profile: $profile) {
                ${queries.fields.profile}
              }
            }
          `

export default queries
