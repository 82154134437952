import React from 'react'

const AuthenticatedLocalContext = React.createContext<
  | {
      isAuthenticatedLocal: Function
      setAuthenticatedLocal: Function
      unsetAuthenticatedLocal: Function
    }
  | undefined
>(undefined)

type AuthenticatedLocalProviderProps = { children: React.ReactNode }

const AuthenticatedLocalProvider = ({
  children,
}: AuthenticatedLocalProviderProps) => {
  // console.log('AuthenticatedLocalProvider()')

  const isAuthenticatedLocal = () => {
    if (
      typeof window !== 'undefined' &&
      sessionStorage.getItem('authenticated-local') &&
      sessionStorage.getItem('authenticated-local') !== null
    ) {
      return true
    } else {
      return false
    }
  }

  const setAuthenticatedLocal = () => {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem('authenticated-local', 'true')
    }
  }
  const unsetAuthenticatedLocal = () => {
    if (typeof window !== 'undefined') {
      sessionStorage.removeItem('authenticated-local')
    }
  }

  const value = {
    isAuthenticatedLocal,
    setAuthenticatedLocal,
    unsetAuthenticatedLocal,
  }

  return (
    <AuthenticatedLocalContext.Provider value={value}>
      {children}
    </AuthenticatedLocalContext.Provider>
  )
}

const useAuthenticatedLocal = () => {
  const context = React.useContext(AuthenticatedLocalContext)
  if (context === undefined) {
    throw new Error(
      'useAuthenticatedLocal must be used within a AuthenticatedLocalProvider'
    )
  }
  return context
}

export { AuthenticatedLocalProvider, useAuthenticatedLocal }
