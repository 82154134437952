import { createTheme, alpha } from '@mui/material/styles'
import { rgb } from 'polished'

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    fontFamilySerif: string
    fontWeightSemiBold: number
    h1Large: React.CSSProperties
    h4Sans: React.CSSProperties
    smallText: React.CSSProperties
    largeText: React.CSSProperties
    smallHeading: React.CSSProperties
  }
  interface TypographyOptions {
    fontFamilySerif?: string
    fontWeightSemiBold?: number
    h1Large: React.CSSProperties
    h4Sans: React.CSSProperties
    smallText: React.CSSProperties
    largeText: React.CSSProperties
    smallHeading: React.CSSProperties
  }
}

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    fontFamilySerif: true
    fontWeightSemiBold: true
    h1Large: true
    h4Sans: true
    smallText: true
    largeText: true
    smallHeading: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    outlineLight: string
    outlineDark: string
    outlineDarkSoft: string
    dividerLight: string
    textLight: string
    textDark: string
    textGrey: string
    lightGrey: string
    textAccountSidebar: string
    buttonDefault: string
    shadows: {
      light: string
      lightWhiteBg: string
      strong: string
      strongWhiteBg: string
    }
  }
  interface PaletteOptions {
    outlineLight?: string
    outlineDark?: string
    outlineDarkSoft?: string
    dividerLight?: string
    textLight?: string
    textDark?: string
    textGrey?: string
    lightGrey?: string
    textAccountSidebar?: string
    buttonDefault?: string
    shadows?: {
      light?: string
      lightWhiteBg?: string
      strong?: string
      strongWhiteBg?: string
    }
  }
}
declare module '@mui/material/styles' {
  interface Shadows {
    light: string
    lightWhiteBg: string
    strong: string
    strongWhiteBg: string
  }
  interface ShadowsOptions {
    light?: string
    lightWhiteBg?: string
    strong?: string
    strongWhiteBg?: string
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    masonrySpacing: string
    twoColLayoutExtraHeight: string
    appbarDrawerWidth: string
    appbarHeightSmall: string
    appbarHeightLarge: string
    buttonSize: {
      lg: number
      xl: number
    }
    fixedHeroHeight: {
      xs: number
      sm: number
      md: number
      lg: number
      xl: number
    }
    shape?: {
      borderRadius?: number
      borderRadiusSmall?: number
      borderRadiusLarge?: number
    }
  }
  interface ThemeOptions {
    masonrySpacing: string
    twoColLayoutExtraHeight: string
    appbarDrawerWidth: string
    appbarHeightSmall: string
    appbarHeightLarge: string
    buttonSize: {
      lg: number
      xl: number
    }
    fixedHeroHeight: {
      xs: number
      sm: number
      md: number
      lg: number
      xl: number
    }
    shape?: {
      borderRadius?: number
      borderRadiusSmall?: number
      borderRadiusLarge?: number
    }
  }
}

const fonts = {
  sans: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  serif: "'Crimson Text', serif;",
}

const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
}

const colors = {
  primary: '#4bbfff',
  secondary: '#1d2434',
  outlineLight: 'rgba(255,255,255,.3)',
  outlineDark: 'rgba(0,24,61,.15)',
  outlineDarkSoft: 'rgba(47,58,80,.1)',
  dividerLight: 'rgba(244,247,249,.1)',
  textLight: '#acbcc8',
  textDark: '#2f3450',
  textGrey: '#8d929d',
  lightGrey: '#f2f6f8',
  textAccountSidebar: '#7a7d8f',
  buttonDefault: '#f0f2f4',
}

const borderRadiuses = {
  small: 4,
  medium: 6,
  large: 8,
}

const headingBase = {
  fontFamily: fonts.serif,
  fontWeight: fontWeights.medium,
  lineHeight: 1.2,
}

let theme = createTheme({
  // transitions: {
  //   duration: {
  //     standard: 3000,
  //   },
  // },

  masonrySpacing: '16px',
  twoColLayoutExtraHeight: '60px',
  appbarDrawerWidth: '360px',
  appbarHeightSmall: '66px',
  appbarHeightLarge: '84px',
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1400,
    },
  },
  buttonSize: {
    lg: 48,
    xl: 56,
  },
  fixedHeroHeight: {
    xs: 500,
    sm: 550,
    md: 600,
    lg: 700,
    xl: 800,
  },
  spacing: 10,
  shape: {
    borderRadius: borderRadiuses.medium,
    borderRadiusSmall: borderRadiuses.small,
    borderRadiusLarge: borderRadiuses.large,
  },
  palette: {
    mode: 'light',
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    outlineLight: colors.outlineLight,
    outlineDark: colors.outlineDark,
    outlineDarkSoft: colors.outlineDarkSoft,
    dividerLight: colors.dividerLight,
    textLight: colors.textLight,
    textDark: colors.textDark,
    textGrey: colors.textGrey,
    lightGrey: colors.lightGrey,
    textAccountSidebar: colors.textAccountSidebar,
    buttonDefault: colors.buttonDefault,
    text: {
      primary: '#54586f',
      secondary: '#8d929d',
    },
    shadows: {
      light: '0 2px 2px rgba(0, 0, 0, 0.1)',
      lightWhiteBg: '0 2px 2px rgba(0, 0, 0, 0.18)',
      strong: '0 6px 8px rgba(0, 0, 0, 0.1)',
      strongWhiteBg: '0 6px 8px rgba(0, 0, 0, 0.18)',
    },
    error: {
      main: '#ff5964',
      light: '#fdeeef',
    },
    success: {
      main: '#3bb273',
      light: '#dfefe3',
    },
  },
  typography: {
    fontFamily: fonts.sans,
    htmlFontSize: 10,
    fontSize: 17,
    fontFamilySerif: fonts.serif,
    fontWeightLight: fontWeights.light,
    fontWeightRegular: fontWeights.regular,
    fontWeightMedium: fontWeights.medium,
    fontWeightSemiBold: fontWeights.semiBold,
    fontWeightBold: fontWeights.bold,
    h1Large: {
      ...headingBase,
    },
    h1: {
      ...headingBase,
    },
    h2: {
      ...headingBase,
    },
    h3: {
      ...headingBase,
    },
    h4: {
      ...headingBase,
    },
    h4Sans: {
      fontFamily: fonts.sans,
      fontSize: '1.7rem',
      fontWeight: fontWeights.semiBold,
      lineHeight: 1.2,
    },
    h5: {
      ...headingBase,
      fontSize: '1.9rem',
    },
    h6: {
      ...headingBase,
    },
    smallText: {
      display: 'inline-block',
      fontWeight: fontWeights.bold,
      fontSize: '1.2rem',
      textTransform: 'uppercase',
      letterSpacing: '0.2rem',
      // @ts-ignore
      '&.border': {
        paddingBottom: '1rem',
        borderBottom: '4px solid rgba(255, 255, 255, 0.4)',
      },
    },
    largeText: {},
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
        },
        containedPrimary: {
          color: 'white',
          ['&:hover']: {
            color: 'white !important',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
          fontWeight: fontWeights.regular,
          textTransform: 'uppercase',
          letterSpacing: '.1rem',
          color: colors.secondary,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          // @ts-ignore
          '&.outline-light': {
            border: `1px solid ${colors.outlineLight}`,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 13,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '1.2rem',
          fontWeight: fontWeights.regular,
          textTransform: 'uppercase',
          letterSpacing: '.1rem',
          color: colors.secondary,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          position: 'relative',
          overflow: 'visible',
          borderBottom: '1px solid #e8e8e8',
          '&:after': {
            content: '""',
            width: '2000px',
            height: '1px',
            backgroundColor: '#e8e8e8',
            position: 'absolute',
            bottom: '-1px',
            left: '100%',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(0,0,0,0.5)',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          alignItems: 'center',
        },
        root: {
          color: 'white',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // Hover state
          '&:hover': {
            ['fieldset']: {
              borderColor: `${colors.primary} !important`,
            },
          },
        },
        input: {
          fontSize: '1.6rem',
          backgroundColor: 'white',
          borderRadius: `${borderRadiuses.small}px !important`,
          '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active':
            {
              WebkitBoxShadow: '0 0 0 300px white inset',
            },
        },
        notchedOutline: {
          borderRadius: `${borderRadiuses.small}px !important`,
          borderWidth: '1px !important',
          borderColor: colors.outlineDarkSoft,
          ['&.Mui-focused']: {
            ['fieldset']: {
              borderColor: `${colors.primary} !important`,
            },
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        rectangular: {
          borderRadius: borderRadiuses.large,
          opacity: 0.7,
        },
      },
    },
  },
})

theme.typography.h1Large = {
  ...theme.typography.h1Large,
  fontSize: '6rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '7rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '8rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '9rem',
  },
}
theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: '4rem',
  lineHeight: 1.1,
  [theme.breakpoints.up('sm')]: {
    fontSize: '5rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '6rem',
    lineHeight: 1.2,
  },
}

theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: '4rem',
  [theme.breakpoints.up('lg')]: {
    fontSize: '5rem',
  },
}
theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: '3rem',
  [theme.breakpoints.up('lg')]: {
    fontSize: '4rem',
  },
}
theme.typography.h4 = {
  ...theme.typography.h4,
  fontSize: '2.5rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.7rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.9rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '3rem',
  },
}
theme.typography.largeText = {
  ...theme.typography.largeText,
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.8rem',
    lineHeight: 1.6,
  },
}

// @ts-ignore
theme.components.MuiFormLabel.styleOverrides.root = {
  // @ts-ignore
  ...theme.components.MuiFormLabel.styleOverrides.root,
  fontWeight: theme.typography.fontWeightSemiBold,
  color: theme.palette.textDark,
  marginBottom: theme.spacing(1),
}
// @ts-ignore
theme.components.MuiTab.styleOverrides.root = {
  // @ts-ignore
  ...theme.components.MuiTab.styleOverrides.root,
  textTransform: 'none',
  fontWeight: fontWeights.regular,
  transition: theme.transitions.create(['color']),
  minWidth: 'auto !important',
  '&:hover': {
    color: theme.palette.primary.main,
    opacity: 1,
  },
  // '&$selected': {
  //   color: theme.colors.textDark,
  // },
  // '&:focus': {
  //   color: theme.colors.textDark,
  // },
}

export default theme
