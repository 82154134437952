import { queryGet } from 'account/constants/account-queries'
import { languageFieldValue } from 'account/constants/languages'

export type Dispatch = (action: Action) => void

export type term = {
  slug: string
  name: string
}

type primaryTerms = {
  departments: term[]
  regions: term[]
  levels: term[]
  locations: term[]
}

export type language = {
  language: string
  proficiency: {
    name: string
    slug: string
  }
}

export type languageValue = {
  language: string
  proficiency: string
}

type vote = {
  id: string
  modifiedAt: string
  vote: string
}

export type application = {
  id: string
  modifiedAt: Date
}

type fetched = {
  account: boolean
  interests: boolean
  profile: boolean
  votes: boolean
  applications: boolean
  favoriteMandate: boolean
}

export type mandateId = {
  id: string
}

export type profileTerms = primaryTerms & {
  products: term[]
  sectors: term[]
}

export type profile = profileTerms & {
  yearsOfExperience: number
  currentEmployer: {
    employer: string
    jobTitle: string
  }
  cv: string
  linkedIn: string
  contactInformation: {
    mobileNumber: string
  }
  languages: language[]
}
export type profileFieldValues = profileTerms & {
  yearsOfExperience: number
  employer: string
  jobTitle: string
  cv: string
  linkedIn: string
  mobileNumber: string
  languages: languageFieldValue[]
}

export type mandateIds = mandateId[] | undefined
export type applications = application[] | undefined

export type State = {
  awsAuthenticated: boolean
  tasksComplete: boolean
  account:
    | {
        email: string
        firstName: string
        lastName: string
        picture: string
        title: string
        notifications: boolean
        mailingList: boolean
        privacyPolicy: boolean
      }
    | undefined
  interests: primaryTerms | undefined
  profile: profile | undefined
  votes: vote[] | undefined
  applications: application[] | undefined
  favoriteMandate: mandateIds
  fetched: fetched
}

export const initialState: State = {
  awsAuthenticated: false,
  tasksComplete: false,
  account: undefined,
  interests: undefined,
  profile: undefined,
  applications: undefined,
  votes: undefined,
  favoriteMandate: undefined,
  fetched: {
    account: false,
    interests: false,
    profile: false,
    votes: false,
    applications: false,
    favoriteMandate: false,
  },
}

type Action =
  | {
      type: 'SET_STATE'
      payload: {
        type: keyof queryGet | 'tasksComplete' | 'fetched'
        data: Object | boolean
      }
    }
  | {
      type: 'RESET_STATE'
    }
  | {
      type: 'SET_AWSAUTHENTICATED'
    }
  | {
      type: 'UNSET_AWSAUTHENTICATED'
    }
  | {
      type: 'SIGN_OUT'
    }

export const accountReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'RESET_STATE':
      return {
        ...initialState,
      }
    case 'SET_STATE':
      return {
        ...state,
        [action.payload.type]: action.payload.data,
        fetched: { ...state.fetched, [action.payload.type]: true },
      }
    case 'SET_AWSAUTHENTICATED':
      return {
        ...state,
        awsAuthenticated: true,
      }
    case 'UNSET_AWSAUTHENTICATED':
      return {
        ...state,
        awsAuthenticated: false,
      }
    default:
      return state
  }
}
