import React from 'react'
import { ThemeProvider } from '@mui/material'
import '@fontsource/crimson-text/400.css'
import { AnimatePresence } from 'framer-motion'

import theme from './src/_core/styles/theme'
import { NavDrawerProvider } from './src/public/context/NavDrawerContext'
import { LoadingProvider } from './src/_core/context/LoadingContext'
import { BackdropProvider } from './src/_core/context/BackdropContext'
import { MessageProvider } from './src/_core/context/MessageContext'
import GlobalStyles from './src/_core/components/atoms/globalStyles/GlobalStyles'

import { AuthenticatedLocalProvider } from './src/account/context/AuthenticatedLocalContext'
import { AccountProvider } from './src/account/context/AccountContext'

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }

  if (!('scrollBehavior' in document.documentElement.style)) {
    const ss = await import('smoothscroll-polyfill')
    ss.polyfill()
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <LoadingProvider>
      <MessageProvider>
        <BackdropProvider>
          <AuthenticatedLocalProvider>
            <NavDrawerProvider>
              <AccountProvider>
                <ThemeProvider theme={theme}>
                  <GlobalStyles />
                  {element}
                </ThemeProvider>
              </AccountProvider>
            </NavDrawerProvider>
          </AuthenticatedLocalProvider>
        </BackdropProvider>
      </MessageProvider>
    </LoadingProvider>
  )
}

export const wrapPageElement = ({ element }) => (
  <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
)

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const transitionDelay = 0.3 * 1000 * 2
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    )
  }
  return false
}
