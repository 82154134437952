import React from 'react'

type Action = {
  type: 'setBackdrop'
  value: boolean
}
type Dispatch = (action: Action) => void
type State = {
  backdropOpen: boolean
}
type BackdropProviderProps = { children: React.ReactNode }

const BackdropContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

const backdropReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setBackdrop': {
      state.backdropOpen = action.value
      return {
        ...state,
      }
    }
  }
}

const BackdropProvider = ({ children }: BackdropProviderProps) => {
  const [state, dispatch] = React.useReducer(backdropReducer, {
    backdropOpen: false,
  })
  const value = { state, dispatch }
  return (
    <BackdropContext.Provider value={value}>
      {children}
    </BackdropContext.Provider>
  )
}

const useBackdrop = () => {
  const context = React.useContext(BackdropContext)
  if (context === undefined) {
    throw new Error('useBackdrop must be used within a BackdropProvider')
  }
  return context
}

export { BackdropProvider, useBackdrop }
