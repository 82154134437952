import React from 'react'

type Action =
  | {
      type: 'START_LOADING'
    }
  | {
      type: 'STOP_LOADING'
    }
  | {
      type: 'START_UPLOADING'
    }
  | {
      type: 'STOP_UPLOADING'
    }
type Dispatch = (action: Action) => void
type State = {
  loading: boolean
  uploading: boolean
}
type LoadingProviderProps = { children: React.ReactNode }

const LoadingContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

const loadingReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'START_LOADING': {
      // console.log('START_LOADING')
      state.loading = true
      return {
        ...state,
      }
    }
    case 'STOP_LOADING': {
      // console.log('STOP_LOADING')
      state.loading = false
      return {
        ...state,
      }
    }
    case 'START_UPLOADING': {
      // console.log('START_UPLOADING')
      state.uploading = true
      return {
        ...state,
      }
    }
    case 'STOP_UPLOADING': {
      // console.log('STOP_UPLOADING')
      state.uploading = false
      return {
        ...state,
      }
    }
  }
}

const LoadingProvider = ({ children }: LoadingProviderProps) => {
  const [state, dispatch] = React.useReducer(loadingReducer, {
    loading: false,
  })
  const value = { state, dispatch }
  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  )
}

const useLoading = () => {
  const context = React.useContext(LoadingContext)
  if (context === undefined) {
    throw new Error('useLoading must be used within a LoadingProvider')
  }
  return context
}

export { LoadingProvider, useLoading }
